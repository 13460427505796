#contracts {
	-webkit-mask-image: linear-gradient(
		to bottom,
		transparent 0,
		black var(--top-mask-size, 0),
		black calc(100% - var(--bottom-mask-size, 0)),
		transparent 100%
	);
	mask-image: linear-gradient(to bottom, transparent 0, black var(--top-mask-size, 0), black calc(100% - var(--bottom-mask-size, 0)), transparent 100%);
	--top-mask-size: 0px;
	--bottom-mask-size: 0px;
}

#contracts li,
#contracts > div {
	cursor: pointer;
}
#contracts li:hover,
#contracts > div:hover {
	background-color: rgb(0,0,255,0.04);
}

#contracts.is-top-overflowing {
	--top-mask-size: 48px !important;
}

#contracts.is-bottom-overflowing {
	--bottom-mask-size: 48px !important;
}
