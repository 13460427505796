.ContractPageGrid {
	display: block;
	margin: 0 auto;
	padding: 16px 0;
	width: 800px !important;

	& > div {
		display: inline-block;
		margin: 8px 0;
		padding: 6px 0;
		width: 100% !important;
		vertical-align: middle;
		border-radius: 6px;
		background-color: white;
		box-shadow: 0 0 20px 1px #DDD;

		// &:hover {
		// 	background-color: rgb(250,250,250);
		// }

		& > * {
			display: block;
		}
		
		& > .status-color {
			cursor:help;
			float: left;
			margin-left: 6px;
			margin-right: 20px;
			height: 100%;
			width: 10px;
			border-radius: 6px;
			border:1px solid white;
		}

		& > .status-wait { background-color: orange; }
		& > .status-conflict { background-color: red; }
		& > .status-valid { background-color: green; }

		& > .sendFileBlock {
			& > * {
				display: inline-block;
				vertical-align: middle;
			}

			& > svg {
				cursor: pointer;
				margin-right: 12px;
			}
		}
	}
}
